import { set, sum } from "lodash";
import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import { useForm } from "react-hook-form";
import { Bar } from "react-chartjs-2";
//import Input from "components/shared/Inputs/Inputs";
import SelectList from "components/shared/select/select";
import MetaTags from "react-meta-tags";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import "../../assets/icons/svg/_svg_icons.scss";

const ConsumoEmpresa = () => {
  const [dados, setDados] = useState([]);
  const [logs, setLogs] = useState([]);
  const [num_colunas, setNumColunas] = useState(1);
  const [searching, setSearching] = useState(false);

  /*const dados = [
    {
      "NM_CONDOMINIO": "CONDOMÍNIO ROSSI PIAZZA PRAÇAS RESIDÊNCIAIS",
      "CD_IDA": "1563319",
      "DT_LEITURA": "2024-08-30T09:00:31.000Z",
      "ULTIMA_LEITURA": "2081227",
      "PENULTIMA_LEITURA": "2081081",
      "ALARMES": null,
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "SOCIEDADE HOSPITALAR DE UBERLANDIA S/A - MADRECOR",
      "CD_IDA": "1366246",
      "DT_LEITURA": "2024-08-28T03:27:35.000Z",
      "ULTIMA_LEITURA": "441561",
      "PENULTIMA_LEITURA": "439856",
      "ALARMES": "Corte de Cabo do Pulso 1, Corte de Cabo do Pulso 2 e Status da válvula",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "CONDOMINIO RESIDENCIAL PARQUE URUGUAIANA",
      "CD_IDA": "1780522",
      "DT_LEITURA": "2024-08-30T07:41:39.000Z",
      "ULTIMA_LEITURA": "589300",
      "PENULTIMA_LEITURA": "589294",
      "ALARMES": "Corte de Cabo do Pulso 1 e Status da válvula",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "UNIMAIS EMPREENDIMENTOS IMOBILIARIOS LTDA",
      "CD_IDA": "786292",
      "DT_LEITURA": "2024-08-30T08:12:37.000Z",
      "ULTIMA_LEITURA": "373622",
      "PENULTIMA_LEITURA": "373596",
      "ALARMES": "Corte de Cabo do Pulso 1 e Status da válvula",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "CONDOMINIO RESIDENCIAL JARDINS IMBAUBA",
      "CD_IDA": "1224743",
      "DT_LEITURA": "2024-08-30T06:37:35.000Z",
      "ULTIMA_LEITURA": "669631",
      "PENULTIMA_LEITURA": "668777",
      "ALARMES": "Corte de Cabo do Pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residência Vila  Verde ",
      "CD_IDA": "1194364",
      "DT_LEITURA": "2024-11-22T20:37:30.000Z",
      "ULTIMA_LEITURA": "644685",
      "PENULTIMA_LEITURA": "644661",
      "ALARMES": "Corte de Cabo do Pulso 1 e Status da válvula",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residencial Atlanta",
      "CD_IDA": "1948652",
      "DT_LEITURA": "2024-11-22T20:32:45.000Z",
      "ULTIMA_LEITURA": "75719079",
      "PENULTIMA_LEITURA": "75718509",
      "ALARMES": "Corte de Cabo do Pulso 1, Corte de Cabo do Pulso 2 e Status da válvula",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "RESIDENCIAL VARANDAS DO PRAIA",
      "CD_IDA": "1081225",
      "DT_LEITURA": "2024-09-27T00:36:00.000Z",
      "ULTIMA_LEITURA": "707999",
      "PENULTIMA_LEITURA": "707958",
      "ALARMES": "Corte de Cabo do Pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residencial  Uma",
      "CD_IDA": "2043319",
      "DT_LEITURA": "2024-11-22T21:00:27.000Z",
      "ULTIMA_LEITURA": "987003",
      "PENULTIMA_LEITURA": "986900",
      "ALARMES": "Corte de Cabo do Pulso 1, Vazamento no pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residencial  Spazio Urano",
      "CD_IDA": "1380559",
      "DT_LEITURA": "2024-11-22T19:51:07.000Z",
      "ULTIMA_LEITURA": "765486",
      "PENULTIMA_LEITURA": "765446",
      "ALARMES": "Corte de Cabo do Pulso 1, Vazamento no pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "CONDOMÍNIO ROSSI PIAZZA PRAÇAS RESIDÊNCIAIS",
      "CD_IDA": "1563319",
      "DT_LEITURA": "2024-08-30T09:00:31.000Z",
      "ULTIMA_LEITURA": "2081227",
      "PENULTIMA_LEITURA": "2081081",
      "ALARMES": null,
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "SOCIEDADE HOSPITALAR DE UBERLANDIA S/A - MADRECOR",
      "CD_IDA": "1366246",
      "DT_LEITURA": "2024-08-28T03:27:35.000Z",
      "ULTIMA_LEITURA": "441561",
      "PENULTIMA_LEITURA": "439856",
      "ALARMES": "Corte de Cabo do Pulso 1, Corte de Cabo do Pulso 2 e Status da válvula",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "CONDOMINIO RESIDENCIAL PARQUE URUGUAIANA",
      "CD_IDA": "1780522",
      "DT_LEITURA": "2024-08-30T07:41:39.000Z",
      "ULTIMA_LEITURA": "589300",
      "PENULTIMA_LEITURA": "589294",
      "ALARMES": "Corte de Cabo do Pulso 1 e Status da válvula",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "UNIMAIS EMPREENDIMENTOS IMOBILIARIOS LTDA",
      "CD_IDA": "786292",
      "DT_LEITURA": "2024-08-30T08:12:37.000Z",
      "ULTIMA_LEITURA": "373622",
      "PENULTIMA_LEITURA": "373596",
      "ALARMES": "Corte de Cabo do Pulso 1 e Status da válvula",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "CONDOMINIO RESIDENCIAL JARDINS IMBAUBA",
      "CD_IDA": "1224743",
      "DT_LEITURA": "2024-08-30T06:37:35.000Z",
      "ULTIMA_LEITURA": "669631",
      "PENULTIMA_LEITURA": "668777",
      "ALARMES": "Corte de Cabo do Pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residência Vila  Verde ",
      "CD_IDA": "1194364",
      "DT_LEITURA": "2024-11-22T20:37:30.000Z",
      "ULTIMA_LEITURA": "644685",
      "PENULTIMA_LEITURA": "644661",
      "ALARMES": "Corte de Cabo do Pulso 1 e Status da válvula",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residencial Atlanta",
      "CD_IDA": "1948652",
      "DT_LEITURA": "2024-11-22T20:32:45.000Z",
      "ULTIMA_LEITURA": "75719079",
      "PENULTIMA_LEITURA": "75718509",
      "ALARMES": "Corte de Cabo do Pulso 1, Corte de Cabo do Pulso 2 e Status da válvula",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "RESIDENCIAL VARANDAS DO PRAIA",
      "CD_IDA": "1081225",
      "DT_LEITURA": "2024-09-27T00:36:00.000Z",
      "ULTIMA_LEITURA": "707999",
      "PENULTIMA_LEITURA": "707958",
      "ALARMES": "Corte de Cabo do Pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 1,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residencial  Uma",
      "CD_IDA": "2043319",
      "DT_LEITURA": "2024-11-22T21:00:27.000Z",
      "ULTIMA_LEITURA": "987003",
      "PENULTIMA_LEITURA": "986900",
      "ALARMES": "Corte de Cabo do Pulso 1, Vazamento no pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    },
    {
      "NM_CONDOMINIO": "Residencial  Spazio Urano",
      "CD_IDA": "1380559",
      "DT_LEITURA": "2024-11-22T19:51:07.000Z",
      "ULTIMA_LEITURA": "765486",
      "PENULTIMA_LEITURA": "765446",
      "ALARMES": "Corte de Cabo do Pulso 1, Vazamento no pulso 1 e Corte de Cabo do Pulso 2",
      "SEM_CONSUMO": 0,
      "TRAVADO": 0,
      "DS_BATERIA": "100%",
      "NR_HIDROMETRO": "1234567890"
    }];*/
  let temp_logs = [];
  const pegarDados = () => {
    if(!searching && dados.length === 0){
      setSearching(true);
      Swal.fire({
        title: 'Pesquisando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    }
   

    api.post(`/monitoring/`).then((response) => {
      setDados(response.data);
      atualizaLogs(response.data);
      Swal.close();
    });
  }
  const atualizaLogs = (content) => {
    let temp_logs = [];
 
    content.forEach(dado => {

      if (dado.ALARMES && dado.ALARMES.indexOf('Corte') > -1) {
        temp_logs.push([
          {
            'NM_CONDOMINIO': dado.NM_CONDOMINIO,
            'NR_HIDROMETRO': dado.NR_HIDROMETRO,
            "DT_LEITURA": new Date(dado.DT_LEITURA).toLocaleString('pt-BR'),
            "ERRO": 'corte'
          }
        ]);
      }

      if (dado.ALARMES && dado.ALARMES.indexOf('Vazamento') > -1) {
        temp_logs.push([
          {
            'NM_CONDOMINIO': dado.NM_CONDOMINIO,
            'NR_HIDROMETRO': dado.NR_HIDROMETRO,
            "DT_LEITURA": new Date(dado.DT_LEITURA).toLocaleString('pt-BR'),
            "ERRO": 'vazamento'
          }
        ]);
      }

      if (dado.ALARMES && dado.ALARMES.indexOf('Ataque') > -1) {
        temp_logs.push([
          {
            'NM_CONDOMINIO': dado.NM_CONDOMINIO,
            'NR_HIDROMETRO': dado.NR_HIDROMETRO,
            "DT_LEITURA": new Date(dado.DT_LEITURA).toLocaleString('pt-BR'),
            "ERRO": 'magnetico'
          }
        ]);
      }

      if (parseInt(dado.ULTIMA_LEITURA) < parseInt(dado.PENULTIMA_LEITURA)) {
        temp_logs.push([
          {
            'NM_CONDOMINIO': dado.NM_CONDOMINIO,
            'NR_HIDROMETRO': dado.NR_HIDROMETRO,
            "DT_LEITURA": new Date(dado.DT_LEITURA).toLocaleString('pt-BR'),
            "ERRO": 'reverso'
          }
        ]);
      }

      if (dado.TRAVADO > 0) {
        temp_logs.push([
          {
            'NM_CONDOMINIO': dado.NM_CONDOMINIO,
            'NR_HIDROMETRO': dado.NR_HIDROMETRO,
            "DT_LEITURA": new Date(dado.DT_LEITURA).toLocaleString('pt-BR'),
            "ERRO": 'travado'
          }
        ]);
      }

      if (dado.SEM_CONSUMO > 0) {
        temp_logs.push([
          {
            'NM_CONDOMINIO': dado.NM_CONDOMINIO,
            'NR_HIDROMETRO': dado.NR_HIDROMETRO,
            "DT_LEITURA": new Date(dado.DT_LEITURA).toLocaleString('pt-BR'),
            "ERRO": 'pulso'
          }
        ]);
      }

      if (parseInt(dado.DS_BATERIA.replace('%', '')) < 49) {
        temp_logs.push([
          {
            'NM_CONDOMINIO': dado.NM_CONDOMINIO,
            'NR_HIDROMETRO': dado.NR_HIDROMETRO,
            "DT_LEITURA": new Date(dado.DT_LEITURA).toLocaleString('pt-BR'),
            "ERRO": 'bateria'
          }
        ]);
      }

      temp_logs.sort((a, b) => {
        const dataA = new Date(a[0].DT_LEITURA.split('/').reverse().join('-'));
        const dataB = new Date(b[0].DT_LEITURA.split('/').reverse().join('-'));
        return dataB - dataA;
      });
      setLogs(temp_logs);
      setNumColunas(Math.ceil(temp_logs.length / 3));
    });
  }
  useEffect(() => {
    pegarDados();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      pegarDados();
    }, 90000);

    return () => clearInterval(interval);
  }, []);


  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consumo Empresas</title>
        </MetaTags>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div id='empresas' className="col-md-9" style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }}>
            <h1 style={{ color: "#0057AA", marginBottom: "30px", fontWeight: "600" }}>Consumo Empresas</h1>
            <div
              ref={(el) => {
                if (el) {
                  let atualScrol = 0;
                  let somaInternal = true;

                  // Calcular largura real do conteúdo
                  const cardWidth = 300; // largura fixa de cada card
                  const totalWidth = cardWidth * Math.ceil(dados.length / 3); // total de colunas considerando 3 cards por coluna

                  if (num_colunas > 9 && !el.interval) {
                    el.interval = setInterval(() => {
                      atualScrol = somaInternal ? atualScrol + 5 : atualScrol - 5;

                      // Ajustar limites do scroll baseado na largura real
                      if (atualScrol >= totalWidth - el.clientWidth) {
                        somaInternal = false;
                        atualScrol = totalWidth - el.clientWidth;
                      }
                      if (atualScrol <= 0) {
                        somaInternal = true;
                        atualScrol = 0;
                      }

                      el.scrollTo({
                        left: atualScrol,
                        behavior: 'smooth'
                      });
                    }, 150);
                  }
                }
              }}
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${num_colunas}, 300px)`, // Definir largura fixa das colunas
                gridTemplateRows: "repeat(3, auto)",
                gridAutoFlow: "column",
                gap: "10px",
                overflowX: "hidden",
                scrollBehavior: "smooth",
                WebkitOverflowScrolling: "touch",
                msOverflowStyle: "-ms-autohiding-scrollbar",
                padding: "10px 0"
              }}
            >
              {dados.map((dado, index) => (
                <div key={index} style={{
                  border: "1px solid #0057AA",
                  borderRadius: "10px",
                  padding: "15px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 8px rgba(0,87,170,0.1)",
                  transition: "transform 0.2s ease-in-out",
                  cursor: "pointer",
                  width: `${(100 / num_colunas) * num_colunas}%`,
                  minWidth: num_colunas > 9 ? "300px" : "auto"
                }}>
                  <span style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    fontSize: "14px",
                    lineHeight: "1.3"
                  }}>
                    <div style={{ fontWeight: "600", color: "#0057AA", fontSize: "16px", marginBottom: "3px" }}>
                      {dado.NM_CONDOMINIO}
                    </div>
                    <div>Leitura Atual: <strong>{dado.ULTIMA_LEITURA}</strong></div>
                    <div>Data: <strong>{new Date(dado.DT_LEITURA).toLocaleDateString()}</strong></div>
                    <div>Hidrometro: <strong>{dado.NR_HIDROMETRO}</strong></div>
                    <div>IDA: <strong>{dado.CD_IDA}</strong></div>
                    <div>
                      Erros:
                      {dado.ALARMES && dado.ALARMES.indexOf('Corte') > -1 ? <> <i className="svg-corte" style={{ color: "#ff4444" }}></i></> : <></>}
                      {dado.ALARMES && dado.ALARMES.indexOf('Vazamento') > -1 ? <> <i className="svg-vazamento" style={{ color: "#ff4444" }}></i></> : <></>}
                      {dado.ALARMES && dado.ALARMES.indexOf('Ataque') > -1 ? <> <i className="svg-magnetico" style={{ color: "#ff4444" }}></i></> : <></>}
                      {parseInt(dado.ULTIMA_LEITURA) < parseInt(dado.PENULTIMA_LEITURA) ? <> <i className="svg-reverso" style={{ color: "#ff4444" }}></i></> : <></>}
                      {dado.TRAVADO > 0 ? <> <i className="svg-travado" style={{ color: "#ff4444" }}></i></> : <></>}
                      {dado.SEM_CONSUMO > 0 ? <> <i className="svg-pulso" style={{ color: "#ff4444" }}></i></> : <></>}
                      {parseInt(dado.DS_BATERIA.replace('%', '')) < 49 ? <> <i className="svg-bateria" style={{ color: "#ff4444" }}></i></> : <></>}
                    </div>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div id='logs' className="col-md-3" style={{
            borderLeft: "2px solid #0057AA",
            paddingLeft: "20px",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column"
          }}>
            <h2 style={{ color: "#0057AA", marginBottom: "30px", fontWeight: "600" }}>Log empresas</h2>
            <div style={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 150px)",
              paddingRight: "10px",
              display: "flex",
              flexDirection: "column-reverse"
            }}>
              {logs.map((log, index) => (
                <div key={index} style={{
                  border: "1px solid #0057AA",
                  borderRadius: "10px",
                  padding: "15px",
                  marginBottom: "20px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 8px rgba(0,87,170,0.1)",
                  transition: "transform 0.2s ease-in-out",
                  cursor: "pointer"
                }}>
                  <span style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    fontSize: "14px",
                    lineHeight: "1.3"
                  }}>
                    <div style={{ fontWeight: "600", color: "#0057AA", fontSize: "16px", marginBottom: "3px" }}>
                      {log.NM_CONDOMINIO}
                    </div>
                    <div>Nome do condomínio: <strong>{log[0].NM_CONDOMINIO}</strong></div>
                    <div>Hidrometro: <strong>{log[0].NR_HIDROMETRO}</strong></div>
                    <div>Data do registro: <strong>{log[0].DT_LEITURA}</strong> </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>Erro: <i className={`svg-${log[0].ERRO}`} style={{ color: "#ff4444" }}></i></div>
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            padding: "5px",
            backgroundColor: "rgba(248, 249, 250, 0.6)",
            borderTop: "1px solid #0057AA",
            backdropFilter: "blur(5px)",
            zIndex: 1000
          }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 20px" }}>
              <div><i className="svg-corte" style={{ color: "#ff4444" }}></i> Corte de Cabo</div>
              <div><i className="svg-vazamento" style={{ color: "#ff4444" }}></i> Vazamento Detectado</div>
              <div><i className="svg-magnetico" style={{ color: "#ff4444" }}></i> Ataque Magnético</div>
              <div><i className="svg-reverso" style={{ color: "#ff4444" }}></i>Consumo Reverso</div>
              <div><i className="svg-travado" style={{ color: "#ff4444" }}></i> Medidor Travado</div>
              <div><i className="svg-pulso" style={{ color: "#ff4444" }}></i> Medidor Sem Consumo</div>
              <div><i className="svg-bateria" style={{ color: "#ff4444" }}></i> Bateria Baixa</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConsumoEmpresa;
