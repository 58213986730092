import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";


import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import Swal from "sweetalert2";
import api from "../../services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Hidrometro = () => {
  const initialState = {
    id: "",
    number: "",
    desc: "",
    model: "",
    status: "",
    tipo: "",
    acesso: "",
  };

  const [hydrometers, setHydrometers] = useState([]);
  const [values, setValues] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [search, setSearch] = useState(initialState);
  const [modalMaster, setModalMaster] = useState(false);
  const [showList, setShowList] = useState(false);
  const [hidrometerId, setHidrometerId] = useState("");
  const [nrHidrometer, setNrHidrometer] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  let history = useHistory();
  const routeChange = (newRoute) => {
    history.push(newRoute)
  }
  const newHidros = () => {
    api.get("/hydrometers").then((response) => {
      let arr = [];
      arr = formatArray(response.data);
      setHydrometers(arr);
      setValues(arr);
    });
  }


  useEffect(() => {
    newHidros();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    let empty = false;
    let body = {
      NR_HIDROMETRO: search.number,
      DS_DESCRICAO_HIDROMETRO: search.desc,
      DS_MODELO_HIDROMETRO: search.model,
      TP_ATIVO: search.status,
      TP_HIDROMETRO: search.tipo,
      TP_ACESSO: search.acesso
    };

    if (search === initialState) {
      empty = true;
    }

    if (empty) {
      setValues(hydrometers);
      Swal.fire({
        icon: "success",
        timer: 1000,
      });
      setShowList(false);
      return;
    }

    api
      .post("/hydrometers/filter", body)
      .then((response) => {
        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setValues(hydrometers);
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 1000,
          });
          setValues(formatArray(response.data));
        }
      })
      .catch(function (error) {
        setValues(hydrometers);
      });
  };
  const confirmDelete = () => {
    api.post(`/hydrometers/delete/${hidrometerId}`).then((response) => {
      toggle();
      console.log(response.data)
      if (response.data != true) {
        Swal.fire({
          icon: "warning",
          text: response.data,
        });
      } else {

        Swal.fire({
          icon: "success",
          text: "Hidrometro Deletado",
          timer: 2000,
        });
        newHidros()
      }
    }).catch((error) => {

      Swal.fire({
        icon: "warning",
        text: "Não foi possível fazer a exclusão, por favor contate o administrador",
      });
    })

  }
  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };
  const formatPhone = (number) => {
    number = number.toString();
    const ddd = number.substring(0, 2);
    const init = number.substring(2, 6);
    const end = number.substring(6, 11);
    return '(' + ddd + ')' + init + '-' + end

  }
  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      arr.push({
        id: elem.OID_HIDROMETRO,
        number: elem.NR_HIDROMETRO,
        desc: elem.DS_DESCRICAO_HIDROMETRO,
        nmCdm:(elem.NM_CONDOMINIO ? elem.NM_CONDOMINIO : ""),
        model: elem.DS_MODELO_HIDROMETRO,
        contact: elem.NR_CONTATO,
        consumption: elem.NR_CONSUMO_LITROS,
        capacity: elem.ID_CAPACIDADE,
        vazao: elem.NR_RANGE_VAZAO,
        status: elem.TP_ATIVO,
        tipo: elem.TP_HIDROMETRO,
        acesso: elem.TP_ACESSO,
      });
    });
    return arr;
  };
  const ApiMaster = (number) => {
    api.get("/hydrometersmastercdmn/" + number).then((response) => {
      setMasterList(response.data);
    });
  };

  function ModlaMaster(number) {
    ApiMaster(number);
    setModalMaster(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Hidrômetro</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Hidrômetro</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
              <Col md={4}>
                <div className="float-end d-none d-md-block">
                  <Link to="/cadastro/hidrometro-cadastro">
                    <Button color="primary" className="btn-lg">
                      Cadastrar
                      <i className="dripicons-document-new" />
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={onSubmit}>
                  <Row className="mb-3">
                    <label htmlFor="codigo" className="col-md-2 col-form-label">
                      Número Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_HIDROMETRO"
                        value={search.number}
                        onChange={onChangeHandler("number")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Descrição
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_DESCRICAO_HIDROMETRO"
                        value={search.desc}
                        onChange={onChangeHandler("desc")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="descricao"
                      className="col-md-2 col-form-label"
                    >
                      Modelo
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_MODELO_HIDROMETRO"
                        value={search.model}
                        onChange={onChangeHandler("model")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-2">
                      <label htmlFor="descricao" className="switch">
                        Status
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        required={
                          search.number === "" &&
                            search.desc === "" &&
                            search.model === ""
                            ? false
                            : search.number === ""
                              ? true
                              : false
                        }
                        disabled={search.number !== "" ? true : false}
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("status")}
                      >
                        <option value="">Selecione...</option>
                        <option value="S">Ativo</option>
                        <option value="N">Inativo</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-2">
                      <label htmlFor="descricao" className="switch">
                        Tipo
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        required={
                          search.number === "" &&
                            search.desc === "" &&
                            search.model === ""
                            ? false
                            : search.number === ""
                              ? true
                              : false
                        }
                        disabled={search.number !== "" ? true : false}
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("tipo")}
                      >
                        <option value="">Selecione...</option>
                        <option value="M">Master</option>
                        <option value="U">Único</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-2">
                      <label htmlFor="descricao" className="switch">
                        Acesso
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        required={
                          search.number === "" &&
                            search.desc === "" &&
                            search.model === ""
                            ? false
                            : search.number === ""
                              ? true
                              : false
                        }
                        disabled={search.number !== "" ? true : false}
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("acesso")}
                      >
                        <option value="">Selecione...</option>
                        <option value="L">Livre</option>
                        <option value="IT">Interno Trancado</option>
                        <option value="ET">Externo Trancado</option>

                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar
                        <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <ExcelFile filename='Hidrometros'>
            <ExcelSheet data={values} name="Hidrômetros">
              <ExcelColumn label="Número Hidrômetro" value="number" />
              <ExcelColumn label="Tipo" value="tipo" />
              <ExcelColumn label="Acesso" value="acesso" />
              <ExcelColumn label="Modelo" value="model" />
              <ExcelColumn label="Descrição" value="desc" />
              <ExcelColumn label="Capacidade" value="capacity" />
              <ExcelColumn label="Consumo" value="consumption" />

              <ExcelColumn label="Vazão" value="vazao" />
              <ExcelColumn
                label="Status"
                value={(status) => (status ? "Ativo" : "Inativo")}
              />
            </ExcelSheet>
          </ExcelFile>

          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Número Hidrômetro</th>
                  <th className="align-middle">Tipo</th>
                  <th className="align-middle">Acesso</th>

                  <th className="align-middle">Modelo</th>
                  <th className="align-middle">Contato</th>
                  <th className="align-middle">Descrição</th>
                  <th className="align-middle">Nome do Cond.</th>
                  <th className="align-middle">Capacidade</th>

                  <th className="align-middle">Consumo</th>
                  <th className="align-middle">Vazão</th>
                  <th className="align-middle">Status</th>
                  <th className="align-middle"></th>
                </tr>
              </thead>
              <tbody>
                {values?.map((hydrometer, key) => (
                  <tr key={"_tr_" + key}>
                    <td>
                      {hydrometer.tipo == "M" ? (
                        <button
                          onClick={() => {
                            ModlaMaster(hydrometer.id);
                          }}
                        >
                          {hydrometer.number}
                        </button>
                      ) : (
                        hydrometer.number
                      )}
                    </td>
                    <td>{hydrometer.tipo == "M" ? "Master" : "Único"} </td>
                    <td>{hydrometer.acesso == "L" ? "Livre" : hydrometer.acesso == "IT" ? "Interno Trancado" : "Externo Trancado"} </td>

                    <td>{hydrometer.model}</td>
                    <td>{hydrometer.contact ? formatPhone(hydrometer.contact) : "Não Registrado"}</td>

                    <td>{hydrometer.desc}</td>
                    <td>{hydrometer.nmCdm}</td>

                    <td>{hydrometer.capacity}</td>

                    <td>{hydrometer.consumption}</td>
                    <td>{hydrometer.vazao}</td>
                    <td>
                      {hydrometer.status == "S" ? (
                        <span className="badge bg-success">Ativo</span>
                      ) : (
                        <span className="badge bg-danger bg-primary">
                          Inativo
                        </span>
                      )}
                    </td>
                    <td>

                      <Button
                        type="button"

                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                        onClick={() => { routeChange(`/cadastro/hidrometro-edit/${hydrometer.id}`) }}
                      >
                        <i className="dripicons-document-edit" />
                      </Button>
                      <Button
                        type="button"
                        onClick={() => { setHidrometerId(hydrometer.id); setNrHidrometer(hydrometer.number); toggle(); }}
                        color="link"
                        size="sm"
                        className="btn-light waves-effect waves-light"
                      >
                        <i className="dripicons-trash text-danger" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal isOpen={modalMaster} toggle={ModlaMaster}>
            <ModalHeader
              toggle={ModlaMaster}
              onClick={() => setModalMaster(false)}
              className="w-100"
            >
              <Container fluid className="w-100">
                <Row className="mb-3">
                  <div>
                    Hidrômetros Master
                  </div>

                </Row>
              </Container>
            </ModalHeader>
            <ModalBody>
              <div className="table-responsive">
                <div className="col-md-5">
                  <ExcelFile filename='Hidrômetros Master'>
                    <ExcelSheet data={masterList} name="Hidrômetros">
                      <ExcelColumn label="Número Hidrômetro" value="NR_HIDROMETRO_IMOVEL" />
                      <ExcelColumn label="Conta" value="NR_CONTA_IMOVEL" />
                      <ExcelColumn label="Bloco" value="DS_BLOCO_IMOVEL" />
                      <ExcelColumn label="Complemento" value="DS_COMPLEMENTO_IMOVEL" />
                      <ExcelColumn label="IDA" value="NR_IDA_IMOVEL" />
                    </ExcelSheet>
                  </ExcelFile>

                </div>
                <table className="table align-middle  ">
                  <thead className="table-light">
                    <tr>
                      <th className="align-middle">Número Hidrômetro</th>
                      <th className="align-middle">Conta</th>
                      <th className="align-middle">Bloco</th>
                      <th className="align-middle">Complemento</th>
                      <th className="align-middle">IDA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {masterList?.map((hydrometer, key) => (
                      <tr key={"_tr_" + key}>
                        <td>{hydrometer.NR_HIDROMETRO_IMOVEL}</td>
                        <td>{hydrometer.NR_CONTA_IMOVEL}</td>
                        <td>{hydrometer.DS_BLOCO_IMOVEL}</td>
                        <td>{hydrometer.DS_COMPLEMENTO_IMOVEL}</td>
                        <td>{hydrometer.NR_IDA_IMOVEL}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>

          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader color="primary" toggle={toggle} className="d-flex justify-content-center page-title"><h2>Deseja deletar o hidrometro:</h2><span className="d-flex justify-content-center page-title"> {nrHidrometer}</span> </ModalHeader>
            <ModalBody>
              <Row className="mb-3">

                <div className="btn-lg d-flex justify-content-center mt-3"                >
                  <Button
                    color="primary"
                    className="btn-lg mx-2"
                    type="submit"
                    onClick={() => { confirmDelete() }}
                  >
                    Deletar
                  </Button>
                  <Button
                    color="primary"
                    className="btn-lg mx-2"
                    type="submit"
                    onClick={() => { toggle() }}
                  >
                    Cancelar
                  </Button>
                </div>
              </Row>
            </ModalBody>

          </Modal>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default Hidrometro;
