import React, { useState, useEffect, useRef } from "react";

const SelectList = ({ options = [], onOptionSelect, defaultValue }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef(null);

  // Efeito para definir o valor inicial
  useEffect(() => {
    if (defaultValue && Array.isArray(options)) {
      const selectedOption = options.find((opt) => opt.value === defaultValue);
      if (selectedOption) {
        setSearchText(selectedOption.label);
      }
    }
  }, [defaultValue, options]);

  // Efeito para atualizar as opções filtradas sempre que as opções mudarem
  useEffect(() => {
    if (Array.isArray(options)) {
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [options, searchText]);

  // Função para filtrar as opções com base no texto digitado na label
  const handleInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
  };

  // Função para mostrar as opções
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  // Função para selecionar uma opção (define o label selecionado)
  const handleOptionClick = (option) => {
    setSearchText(option.label);
    setShowOptions(false);
    onOptionSelect(option.value);
  };

  // Função para fechar a lista ao clicar fora
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ width: "100%", position: "relative" }} ref={containerRef}>
      <input
        type="text"
        value={searchText}
        onChange={handleInputChange}
        onClick={toggleOptions}
        placeholder="Digite para filtrar"
        className="form-control form-control-lg"
      />

      {showOptions && (
        <ul
          style={{
            listStyle: "none",
            padding: "0",
            margin: "0",
            position: "absolute",
            width: "100%",
            maxHeight: "300px",
            overflowY: "auto",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            zIndex: 1,
          }}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  borderBottom: "1px solid #eee",
                  ...option.style ? option.style : {} // Aplicando o estilo adicional da opção
                }}
              >
                {option.label}
              </li>
            ))
          ) : (
            <li style={{ padding: "8px", color: "#999" }}>
              Nenhuma opção encontrada
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectList;