import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Button, Card,
  CardBody, Col, Container,
  Row
} from "reactstrap";
import Swal from "sweetalert2";
import api from "../../services/api";
import SelectList from "components/shared/select/select";


const cadastroEquipamentoDmae = () => {
  const [hydrometers, setHydrometers] = useState({ value: "", label: "Selecione..." });
  useEffect(() => {
    api.get("/hydrometers/linked").then((response) => {

      let arr = [];
      response.data.notLinked.map((elem) => {
        arr.push({
          value: elem.OID_HIDROMETRO,
          label: elem.NR_HIDROMETRO,
        });
      });


      setHydrometers(arr);
      response.data.notLinked.length > 0 ? setHydroId(response.data.notLinked[0].OID_HIDROMETRO) : "";

    });
  }, []);

  const [equipamentoLora, setEquipamentoLora] = useState([]);
  const [lora, setLora] = useState({ value: "", label: "Selecione..." })

  useEffect(() => {
    api.post("/equipmentslora/linked").then((response) => {


      let arr = [];
      response.data.notLinked.map((elem) => {
        arr.push({
          value: elem.OID_EQUIPAMENTO_LORA,
          label: elem.NR_EQUIPAMENTO,
          style: { color: "#7491DE" }
        });
      });
      response.data.linked.map((elem) => {
        arr.push({
          value: elem.OID_EQUIPAMENTO_LORA,
          label: elem.NR_EQUIPAMENTO,
          style: { fontWeight: "bold" }
        });
      });
      
      setLora(arr)
    });
  }, []);

  const [hydroId, setHydroId] = useState("");
  const [loraId, setLoraId] = useState("empty");
  const [location, setLocation] = useState("");
  const [region, setRegion] = useState("NORTE");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locationType, setLocationType] = useState("T");
  const [empreendimentoType, setEmpreendimentoType] = useState("C");
  const [clock, setClock] = useState("");

  const onChangeHandler = (prop) => (event) => {
    if (prop === "hydroId") {
      setHydroId(event.target.value);
    } else if (prop === "loraId") {
      setLoraId(event.target.value);
    } else if (prop === "location") {
      setLocation(event.target.value);
    } else if (prop === "region") {
      setRegion(event.target.value);
    } else if (prop === "longitude") {
      setLongitude(event.target.value);
    } else if (prop === "latitude") {
      setLatitude(event.target.value);
    } else if (prop === "locationType") {
      setLocationType(event.target.value);
    } else if (prop === "empreendimentoType") {
      setEmpreendimentoType(event.target.value);
    } else if (prop === "clock") {
      setClock(event.target.value);
    }
  };

  const { register, handleSubmit } = useForm();
  const onSubmit = () => {
    // event.preventDefault();
    let body = {
      OID_HIDROMETRO: hydroId,
      OID_EQUIPAMENTO_LORA: loraId,
      DS_LOCALIDADE: location ? location : "Não Cadastrado",
      DS_LOCALIZACAO_REGIAO: region,
      //DS_LOCALIZACAO_LATITUDE: latitude,
      //DS_LOCALIZACAO_LONGITUDE: longitude,
      TP_EMPREENDIMENTO: empreendimentoType,
      TP_LOCALIZACAO: locationType,
      DS_RELOJOARIA_INICIAL: clock
    }
    api
      .post("/equipmentsdmae", body)
      .then((response) => {
        Array.from(document.querySelectorAll("input,select")).forEach(
          (input) => (input.value = "")
        );

        let statusIcon = ''
        if (response.data.error == true) {
          statusIcon = 'warning'
        } else if (response.data.error == false) {
          statusIcon = 'success'
        }

        Swal.fire({
          icon: statusIcon,
          title: response.data.message,
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "warning",
          text: "Confira todos os dados e faça o cadastro completo!",
        });
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cadastro - Equipamento DMAE</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <Link to="/cadastro/EquipamentoDmae">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" /> Voltar
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_HIDROMETRO"
                      className="col-md-2 col-form-label"
                    >
                      Hidrômetro
                    </label>
                    <div className="col-md-6">

                      <SelectList
                        id="OID_HIDROMETRO"
                        options={hydrometers}
                        onOptionSelect={(handleOptionSelect) => {
                          setHydroId(handleOptionSelect);
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_EQUIPAMENTO_LORA"
                      className="col-md-2 col-form-label"
                    >
                      Equipamento Lora
                    </label>
                    <div className="col-md-6">


                      <SelectList
                        id="OID_HIDROMETRO"
                        options={lora}
                        onOptionSelect={(handleOptionSelect) => {
                          setLoraId(handleOptionSelect);
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIDADE"
                      className="col-md-2 col-form-label"
                    >
                      Localidade
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_LOCALIDADE"
                        value={location}
                        onChange={onChangeHandler("location")}

                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIZACAO_REGIAO"
                      className="col-md-2 col-form-label"
                    >
                      Região
                    </label>
                    <div className="col-md-6">
                      <select
                        onChange={onChangeHandler("region")}
                        name="DS_LOCALIZACAO_REGIAO"
                        className="form-control form-control-lg"
                        required
                      >
                        <option value="NORTE">Norte</option>
                        <option value="LESTE">Leste</option>
                        <option value="OESTE">Oeste</option>
                        <option value="SUL">Sul</option>
                        <option value="CENTRAL">Central</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIZACAO_LONGITUDE"
                      className="col-md-2 col-form-label"
                    >
                      Latitute
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_LOCALIZACAO_LATITUDE"
                        defaultValue=""
                        onChange={onChangeHandler("latitude")}
                        required

                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIZACAO_LONGITUDE"
                      className="col-md-2 col-form-label"
                    >
                      Longitude
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_LOCALIZACAO_LONGITUDE"
                        defaultValue=""
                        onChange={onChangeHandler("longitude")}
                        required
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="TP_LOCALIZACAO"
                      className="col-md-2 col-form-label"
                    >
                      Tipo de Localização
                    </label>
                    <div className="col-md-6">
                      <select
                        name="TP_LOCALIZACAO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("locationType")}
                        value={locationType}
                        required

                      >
                        <option value="F">Final</option>
                        <option defaultChecked value="T">Temporária</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="TP_EMPREENDIMENTO"
                      className="col-md-2 col-form-label"
                    >
                      Tipo de Empreendimento
                    </label>
                    <div className="col-md-6">
                      <select
                        name="TP_EMPREENDIMENTO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("empreendimentoType")}
                        value={empreendimentoType}
                        required

                      >
                        <option value="L">Comercio</option>
                        <option defaultChecked value="C">Condominio</option>
                        <option value="I">industria</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_RELOJOARIA_INICIAL"
                      className="col-md-2 col-form-label"
                    >
                      Relojoaria Inicial
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_RELOJOARIA_INICIAL"
                        defaultValue=""
                        onChange={onChangeHandler("clock")}
                        required

                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button color="primary" className="btn-lg" type="submit">
                        Cadastrar <i className="dripicons-document-new" />
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default cadastroEquipamentoDmae;
