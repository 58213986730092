import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";
import { useForm } from "react-hook-form";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";
import api from "../../services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Consumo = () => {
  const [consumptions, setConsumptions] = useState([]);
  const [pulses, setPulses] = useState([]);
  const [buscaHidrometro, setBuscaHidrometro] = useState(false);
  const [dadosConsumo, setDadosConsumo] = useState({ soma: 0, media: 0 });
  useEffect(() => {
    const consumptionsMap = new Map();
    consumptions.forEach((consumption) => {
      const oldValues = consumptionsMap.get(consumption.pulse_id) || [];

      consumptionsMap.set(consumption.CD_META_DEVICE_ADDR, [
        consumption.NR_VLR_PULSO,
        ...oldValues,
      ]);
    });

    consumptionsMap.forEach((values, key) => {
      const newValues = values.map((value) => Number(value));

      const minPulse = Math.min(...newValues);
      const maxPulse = Math.max(...newValues);

      const pulseDifference = maxPulse - minPulse;
      consumptionsMap.set(key, pulseDifference);
    });

    consumptionsMap.forEach((value, key) => {
      setPulses((oldValues) => [
        oldValues.map((pulse) => ({
          ...pulse,
          id: key,
          value,
        })),
      ]);
    });
  }, [consumptions]);

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (
      data.IDA === "" &&
      data.CD_META_DEVICE_ADDR === "" &&
      data.NR_HIDROMETRO === "" &&
      data.DT_FILTER_INI === "" &&
      data.DT_FILTER_FIM === ""
    ) {
      Swal.fire({
        icon: "warning",
        text: "Por Favor Preencha ao Menos um Campo",
      });
      return;
    }

    if (
      data.DT_FILTER_INI > data.DT_FILTER_FIM
    ) {
      Swal.fire({
        icon: "warning",
        text: "A data Fim não pode ser menor que a data Inicio",
      });
      return;
    }

    let oldValue = {};
    api
      .post("/consumptions", data)
      .then((response) => {
        Swal.fire({
          icon: "success",
        });
        if (data.NR_HIDROMETRO !== "") {
          setBuscaHidrometro(true);
        } else {
          setBuscaHidrometro(false);
        }
        console.log(response.data)
        const result = [];

        for (let i = response.data.length - 1; i >= 0; i--) {
          const consumption = response.data[i];
          const cons =
            Math.trunc(consumption.NR_VLR_PULSO / 10) -
            Math.trunc(oldValue.NR_VLR_PULSO / 10) || 0
          const data = {
            ...consumption,
            DS_MODELO_HIDROMETRO: consumption.DS_MODELO_HIDROMETRO,
            DS_LOCALIDADE: consumption.DS_LOCALIDADE,
            NR_DIFERENCA_CONSUMO: cons > -30 ? cons : 0,
            NR_QUANTIDADE_LITROSMC: consumption.NR_QUANTIDADE_LITROS / 1000,
            LEITURA_ATUAL: parseInt((((parseInt(consumption.NR_VLR_PULSO * parseInt(consumption.NR_PULSO_LITROS))) / 1000) + parseInt(consumption.DS_RELOJOARIA_INICIAL))),
            DT_RX_TIMED: new Date(consumption.DT_RX_TIME).toLocaleDateString(),
            DT_RX_TIMEH: new Date(consumption.DT_RX_TIME).toLocaleTimeString(),

          };
          oldValue = consumption;

          result.push(data);
        }
        let soma = 0;
        result.map((elem) => {

          soma += elem.NR_DIFERENCA_CONSUMO > 0 ? elem.NR_DIFERENCA_CONSUMO : 0
        })
        setDadosConsumo({ soma: soma, media: soma / result.length })
        setConsumptions(result.reverse());
      })
      .catch(function (error) {
        console.log(error)
        Swal.fire({
          icon: "warning",
          text: "Nenhum resultado encontrado, refaça a sua busca!",
        });
      });

  };
  const setColor = (color) => {

    color = parseInt(color)

    if (color == 100) {
      return "#228B22";
    } else if (color > 70) {
      return "#00FF7F"
    } else if (color > 35) {
      return "#f8722d"
    } else {
      return "#CD5C5C"
    }


  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consumo Individualizado</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Consumo</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo(a) ao IoT DMAE
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <label
                      htmlFor="IDA"
                      className="col-md-2 col-form-label"
                    >
                      IDA
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("IDA", {
                          required: false,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="IDA"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="CD_META_DEVICE_ADDR"
                      className="col-md-2 col-form-label"
                    >
                      Número do Equipamento LORA
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("CD_META_DEVICE_ADDR", {
                          required: false,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="CD_META_DEVICE_ADDR"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    { }
                    <label
                      htmlFor="NR_HIDROMETRO"
                      className="col-md-2 col-form-label"
                    >
                      Número do Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("NR_HIDROMETRO", {
                          required: false,
                        })}
                        className="form-control form-control-lg"
                        type="text"
                        name="NR_HIDROMETRO"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DT_FILTER_INI"
                      className="col-md-2 col-form-label"
                    >
                      Data Início
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("DT_FILTER_INI", { required: false })}
                        className="form-control form-control-lg"
                        type="date"
                        name="DT_FILTER_INI"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DT_FILTER_FIM"
                      className="col-md-2 col-form-label"
                    >
                      Data Final
                    </label>
                    <div className="col-md-6">
                      <input
                        {...register("DT_FILTER_FIM", { required: false })}
                        className="form-control form-control-lg"
                        type="date"
                        name="DT_FILTER_FIM"
                        defaultValue=""
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button
                        color="secondary"
                        className="btn-lg"
                        type="submit"
                      >
                        Pesquisar <i className="dripicons-search" />
                      </Button>
                    </div>
                  </Row>
                </form>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid>
          <Row>
            <Col lg={buscaHidrometro? 7: 12}>
              <div className="col-md-12 d-flex ">
                <Row className="col-md-10">
                  <br></br>
                  <ExcelFile filename='Consumo'>
                    <ExcelSheet data={consumptions} name="consumptions">
                      <ExcelColumn label="IDA" value="IDA" />
                      <ExcelColumn
                        label="Nr. Equipamento Lora"
                        value="CD_META_DEVICE_ADDR"
                      />
                      <ExcelColumn label="Num. Hidrômetro" value="NR_HIDROMETRO" />
                      <ExcelColumn
                        label="Modelo Hidrômetro"
                        value="DS_MODELO_HIDROMETRO"
                      />
                      <ExcelColumn label="Localização" value="DS_LOCALIDADE" />
                      <ExcelColumn label="Data" value="DT_RX_TIMED" />
                      <ExcelColumn label="Hora" value="DT_RX_TIMEH" />
                      <ExcelColumn label="Temperatura" value="DS_TEMPERATURA" />
                      <ExcelColumn label="Alarmes" value="NR_ALARME" />
                      <ExcelColumn label="Base Tempo" value="NR_BASE_TEMPO" />
                      <ExcelColumn label="Bateria" value="NR_BATERIA" />
                      <ExcelColumn
                        label="Consumo/Periodo"
                        value="NR_DIFERENCA_CONSUMO"
                      />
                      
                     

                      <ExcelColumn label="Leitura" value="NR_QUANTIDADE_LITROSMC" />
                    </ExcelSheet>
                    <ExcelSheet data={pulses} name="diferenca pulso">
                      <ExcelColumn label="Cód. Hidrômetro" value="NR_HIDROMETRO" />
                      <ExcelColumn label="Localização" value="DS_LOCALIDADE" />
                      <ExcelColumn label="Diferença pulso" value="value" />
                    </ExcelSheet>
                  </ExcelFile>
                </Row>
                
                <Row className="col-md-4 align-items-center">
                  <div>
                    <h4 className="mb-0 font-size-16">
                      {buscaHidrometro ? "Consumo Período:" : ""}
                    </h4>
                    <h5
                      className="mb-0 font-size-16"
                      style={{ color: "#0057AA" }}
                    >
                      {buscaHidrometro ? dadosConsumo.soma + " M³" : ""}
                    </h5>
                  </div>
                </Row>
                <Row className="col-md-4 align-items-center">
                  <div>
                    <h4 className="mb-0 font-size-16">
                      {buscaHidrometro ? "Consumo Médio:" : ""}
                    </h4>
                    <h5
                      className="mb-0 font-size-16"
                      style={{ color: "#0057AA" }}
                    >
                      {buscaHidrometro ? dadosConsumo.media.toFixed(2) + " M³" : ""}
                    </h5>
                  </div>
                </Row>
                <Row className="col-md-4 align-items-center">
                  <div>
                    <h4 className="mb-0 font-size-16">
                      {consumptions[0] ? "Leitura Atual" : ""}
                    </h4>
                    <h5
                      className="mb-0 font-size-16"
                      style={{ color: "#0057AA" }}
                    >
                      {consumptions[0] ? consumptions[0].LEITURA_ATUAL + " M³" : ""}
                    </h5>
                  </div>
                </Row>
                
              </div>
            </Col>
            <Col lg={12}>
              <br></br>
            </Col>
          </Row>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">IDA</th>
                  <th className="align-middle">Número</th>
                  <th className="align-middle">Cód. Hidrômetro</th>
                  <th className="align-middle">Modelo Hidrômetro</th>
                  <th className="align-middle">Localização</th>
                  <th className="align-middle">Data</th>
                  <th className="align-middle">Hora</th>
                  <th className="align-middle">Bateria</th>
                  <th className="align-middle">Base Tempo</th>
                  <th className="align-middle">Vr. Pulso HD</th>
                  <th className="align-middle">Nr Pulso</th>

                  <th className="align-middle">Litros</th>
                  <th className="align-middle">Diferença/Consumo</th>
                  

                  <th className="align-middle">Alarme</th>
                </tr>
              </thead>
              <tbody>
                {consumptions?.map((consumption, key) => (
                  <tr key={"_tr_" + key}>
                    <td>{consumption.IDA}</td>
                    <td>{consumption.CD_META_DEVICE_ADDR} </td>
                    <td>{consumption?.NR_HIDROMETRO}</td>
                    <td>{consumption?.DS_MODELO_HIDROMETRO}</td>
                    <td>{consumption?.DS_LOCALIDADE}</td>
                    <td>
                      {new Date(consumption.DT_RX_TIME).toLocaleDateString()}
                    </td>
                    <td>
                      {new Date(consumption.DT_RX_TIME).toLocaleTimeString()}
                    </td>
                    <td><span style={{ border: "solid 4px", color: setColor(consumption.NR_BATERIA) }}>{consumption.NR_BATERIA}</span></td>
                    <td>{consumption.NR_BASE_TEMPO}</td>
                    <td>{consumption.NR_PULSO_LITROS}</td>
                    <td>{consumption.NR_VLR_PULSO}</td>
                    <td>{consumption.NR_QUANTIDADE_LITROS}</td>
                    <td>{consumption.NR_DIFERENCA_CONSUMO > 0 ? consumption.NR_DIFERENCA_CONSUMO : 0}</td>
                    
                    <td>
                      <span className="badge bg-danger bg-primary">
                        {consumption.NR_ALARME}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Consumo;
